import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill={props?.fill || 'white'} {...props}>
      <path d="M3.5 0.5V2H12.9425L0.5 14.4425L1.5575 15.5L14 3.0575V12.5H15.5V0.5H3.5Z" />
    </Svg>
  )
}

export default Icon
