import { AppContainer } from 'components/Box'
import { RowCenter } from 'components/Box/Flex'
import { Button } from 'components/Button'
import { AppLogo, ArrowUp, Hamburger } from 'components/Svg'
import HeaderLinkArray from 'constants/headerLinks'
import { useState, useCallback } from 'react'
import styled from 'styled-components'
import MobileNav from './MobileNav'

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false)

  const handleDismissMobileMenu = useCallback(() => {
    setMobileMenu(false)
  }, [setMobileMenu])

  const handlePresentMobileMenu = useCallback(() => {
    setMobileMenu(true)
  }, [setMobileMenu])

  return (
    <AppContainer>
      <ControlContainer>
        <AppLogo />
        <LinksRow>
          {HeaderLinkArray.map(item => (
            <StyledLink key={item?.route} href={item.route}>
              {item?.name}
            </StyledLink>
          ))}
        </LinksRow>
        <a href="mailto:info@ideofuzion.com">
          <ContactBtn>Contact Us</ContactBtn>
        </a>
        <MobileNavbarIcon onClick={handlePresentMobileMenu}>
          <Hamburger />
        </MobileNavbarIcon>
        <MobileNav onDismiss={handleDismissMobileMenu} visible={mobileMenu} />
      </ControlContainer>
      <Redirect onClick={() => window?.scrollTo({ top: 0 })}>
        <ArrowUp />
      </Redirect>
    </AppContainer>
  )
}
export default Header

const Redirect = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  z-index: 9999;
  background: ${({ theme }) => theme.colors.primary};
  display: none;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.maxXxl} {
    display: flex;
  }
`

const ControlContainer = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2%;
`
const LinksRow = styled(RowCenter)`
  gap: 40px;
  ${({ theme }) => theme.mediaQueries.maxXxl} {
    display: none;
  }
`

const StyledLink = styled.a`
  font-size: 16px;
  transition: color 150ms ease-in-out;
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`
const ContactBtn = styled(Button)`
  min-width: 260px;
  ${({ theme }) => theme.mediaQueries.maxXxl} {
    display: none;
  }
`
const MobileNavbarIcon = styled.div`
  display: none;
  padding-top: 8px;
  cursor: pointer;
  ${({ theme }) => theme.mediaQueries.maxXxl} {
    display: block;
  }
`
