import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="33" height="32" viewBox="0 0 33 32" fill="#8D8D8D" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.918 16C32.918 7.16375 25.7542 0 16.918 0C8.08172 0 0.917969 7.16375 0.917969 16C0.917969 24.8363 8.08172 32 16.918 32C25.7542 32 32.918 24.8363 32.918 16ZM8.16047 15.8313C12.8248 13.7987 15.9348 12.4594 17.4911 11.8119C21.9348 9.96375 22.858 9.6425 23.4598 9.63188C23.5923 9.63 23.888 9.6625 24.0798 9.81812C24.2417 9.94938 24.2861 10.1269 24.3073 10.2513C24.3286 10.3763 24.3555 10.6594 24.3342 10.8813C24.0936 13.4113 23.0517 19.5506 22.5217 22.3844C22.2973 23.5837 21.8555 23.9856 21.428 24.025C20.4992 24.1106 19.7936 23.4106 18.8936 22.8206C17.4855 21.8981 16.6898 21.3231 15.323 20.4225C13.743 19.3813 14.7673 18.8094 15.6673 17.8744C15.903 17.6294 19.9967 13.9056 20.0761 13.5681C20.0861 13.5256 20.0955 13.3681 20.0017 13.285C19.908 13.2019 19.7698 13.2306 19.6705 13.2531C19.5292 13.285 17.2798 14.7719 12.9217 17.7138C12.283 18.1525 11.7042 18.3656 11.1861 18.355C10.6148 18.3425 9.51609 18.0319 8.69922 17.7663C7.69734 17.4406 6.90109 17.2681 6.97047 16.715C7.00609 16.4269 7.40297 16.1325 8.16047 15.8313Z"
        fill="#28A9EA"
      />
    </Svg>
  )
}

export default Icon
