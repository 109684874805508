import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="#8D8D8D" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2765 4.65008C24.2832 1.65296 20.3022 0.0015611 16.0604 0C7.31994 0 0.20644 7.11275 0.203381 15.8555C0.201882 18.6504 0.932539 21.3784 2.32011 23.7826L0.0703125 32L8.47634 29.795C10.7922 31.0588 13.4001 31.724 16.0536 31.7247H16.0604C24.7994 31.7247 31.9137 24.6113 31.9167 15.8685C31.9182 11.6313 30.2706 7.64802 27.2765 4.65083V4.65008ZM16.0604 29.047H16.0551C13.6905 29.0463 11.3708 28.4105 9.34687 27.21L8.86561 26.9242L3.87721 28.2326L5.20852 23.3689L4.89524 22.8702C3.57611 20.7718 2.87892 18.3463 2.88042 15.8563C2.88348 8.58999 8.79568 2.67779 16.0658 2.67779C19.5859 2.67935 22.8949 4.05174 25.3833 6.54326C27.8719 9.03403 29.2412 12.346 29.2397 15.867C29.2365 23.134 23.3245 29.0463 16.0604 29.0463V29.047ZM23.2894 19.1766C22.8933 18.9782 20.9455 18.0202 20.582 17.8878C20.2186 17.7555 19.9548 17.6895 19.6909 18.0863C19.4271 18.4832 18.6676 19.3758 18.4364 19.6396C18.2052 19.9042 17.9741 19.9369 17.578 19.7384C17.1819 19.54 15.9053 19.1219 14.3915 17.7723C13.2138 16.7215 12.4185 15.4245 12.1875 15.0275C11.9563 14.6307 12.1631 14.4163 12.3607 14.2193C12.5386 14.0415 12.7569 13.7564 12.9553 13.5252C13.1538 13.294 13.2191 13.1284 13.3514 12.8645C13.4838 12.5998 13.4176 12.3688 13.3188 12.1703C13.2199 11.9719 12.4277 10.0216 12.0969 9.22866C11.7753 8.45623 11.4484 8.56114 11.2059 8.54821C10.9747 8.53678 10.7109 8.53454 10.4463 8.53454C10.1817 8.53454 9.75288 8.63332 9.38946 9.03028C9.02603 9.42705 8.00264 10.3859 8.00264 12.3353C8.00264 14.2848 9.42218 16.1696 9.62063 16.4342C9.81901 16.6988 12.4147 20.7003 16.3889 22.4171C17.334 22.8253 18.0722 23.0694 18.6478 23.2518C19.5967 23.5537 20.4603 23.5112 21.143 23.4092C21.9042 23.2952 23.4872 22.4505 23.8172 21.5251C24.147 20.5998 24.147 19.8061 24.0483 19.6411C23.9495 19.4762 23.6849 19.3765 23.2887 19.1781L23.2894 19.1766Z"
        fill="#2AB318"
      />
    </Svg>
  )
}

export default Icon
