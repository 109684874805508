import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="36" height="32" viewBox="0 0 36 32" fill="#8D8D8D" {...props}>
      <path
        d="M28.1682 0H33.5964L21.737 13.5551L35.6891 32H24.7644L16.2084 20.813L6.41808 32H0.986091L13.671 17.5016L0.287109 0H11.4884L19.2227 10.2251L28.1682 0ZM26.2628 28.7506H29.271L9.85415 3.0788H6.62648L26.2628 28.7506Z"
        fill="#0F141A"
      />
    </Svg>
  )
}

export default Icon
