import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="58" height="32" viewBox="0 0 58 32" fill="#8D8D8D" {...props}>
      <path
        d="M32.4714 15.9997C32.4714 24.8363 25.3568 32 16.5802 32C7.80358 32 0.689453 24.8384 0.689453 15.9997C0.689453 7.16112 7.80413 0 16.5802 0C25.3563 0 32.4714 7.16321 32.4714 15.9997ZM49.9043 15.9997C49.9043 24.3182 46.3468 31.0612 41.9588 31.0612C37.5707 31.0612 34.0131 24.3161 34.0131 15.9997C34.0131 7.68342 37.5707 0.93839 41.9588 0.93839C46.3468 0.93839 49.9043 7.68342 49.9043 15.9997ZM57.0346 15.9997C57.0346 23.4527 55.7834 29.4941 54.2401 29.4941C52.6968 29.4941 51.4455 23.4506 51.4455 15.9997C51.4455 8.54895 52.6968 2.50538 54.2406 2.50538C55.7844 2.50538 57.0346 8.5473 57.0346 15.9997Z"
        fill="black"
      />
    </Svg>
  )
}

export default Icon
