import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="33" height="32" viewBox="0 0 33 32" fill="#8D8D8D" {...props}>
      <path
        fill="#1977F3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1387 0C24.9755 0 32.1387 7.18949 32.1387 16.0587C32.1387 24.269 25.9998 31.0394 18.0773 32V20.9659H22.4054L22.4054 20.9659H22.4058L23.3036 16.0587H18.0773V14.3234C18.0773 11.8796 18.9778 10.8528 21.2771 10.7425C21.4176 10.7358 21.5634 10.7325 21.7145 10.7325C22.5292 10.7325 23.185 10.7527 23.5628 10.7924V10.7924C23.5629 10.7924 23.5631 10.7924 23.5632 10.7924V6.34395C22.8478 6.14456 21.0988 5.94453 20.0847 5.94453C19.9175 5.94453 19.7531 5.94702 19.5915 5.95199C19.43 5.95695 19.2714 5.96439 19.1155 5.97434C14.4395 6.27269 12.2735 8.81962 12.2735 13.9442V16.0587H8.97461V16.0587H8.97419V20.9659H12.2735V31.6448C5.30457 29.9089 0.138672 23.5902 0.138672 16.0587C0.138672 7.18949 7.30189 0 16.1387 0Z"
      />
    </Svg>
  )
}

export default Icon
