import { Colors } from './types'

export const baseColors = {
  failure: '#ED4B9E',
  primary: '#FF5B00',
  primaryBright: '#FF823C',
  primaryDark: '#0098A1',
  secondary: '#218FC7',
  success: '#31D0AA',
  warning: '#FFB237',
  white: '#ffffff',
}

export const brandColors = {
  binance: '#F0B90B',
}

export const lightColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: '#FAF9FA',
  backgroundDisabled: '#E9EAEB',
  backgroundAlt: '#FFFFFF',
  text: '#452A7A',
  textDisabled: '#BDC2C4',
  textSubtle: '#8f80ba',
  borderColor: '#E9EAEB',
}

export const darkColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: '#02021E',
  backgroundDisabled: '#3c3742',
  backgroundAlt: '#27262c',
  primaryDark: '#0098A1',
  text: '#1F1F1F',
  textDisabled: '#BDC2C4',
  textSubtle: '#8f80ba',
  borderColor: '#E9EAEB',
}
