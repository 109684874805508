import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" {...props}>
      <rect width="40" height="40" rx="20" fill="white" />
      <path d="M25.9425 20.4425L20.25 26.1275V11H18.75V26.1275L13.0575 20.4425L12 21.5L19.5 29L27 21.5L25.9425 20.4425Z" fill="#FF5B00" />
    </Svg>
  )
}

export default Icon
