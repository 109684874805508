import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="33" height="32" viewBox="0 0 33 32" fill="#8D8D8D" {...props}>
      <path
        d="M30.9005 18.6139C31.0486 17.765 31.1261 16.8917 31.1261 15.9997C31.1261 7.68823 24.4256 0.948917 16.1604 0.948917C15.2733 0.948917 14.4051 1.02581 13.5612 1.17521C12.2616 0.426325 10.755 0 9.14977 0C4.23515 0 0.251953 4.00695 0.251953 8.94907C0.251953 10.5631 0.675778 12.079 1.42153 13.3861C1.27213 14.2344 1.19587 15.1077 1.19587 15.9997C1.19587 24.3118 7.89642 31.0517 16.1604 31.0517C17.048 31.0517 17.9163 30.9736 18.7608 30.8242C20.0604 31.5737 21.567 32 23.171 32C28.0856 32 32.07 27.9937 32.07 23.0503C32.07 21.4363 31.6443 19.921 30.9005 18.6139ZM16.2223 25.627C10.9051 25.627 8.4759 22.9203 8.4759 20.9318C8.4759 19.9116 9.27479 19.2103 10.2962 19.2103C12.5704 19.2103 11.9784 22.6227 16.2223 22.6227C18.3939 22.6227 19.666 21.3156 19.666 20.0898C19.666 19.3522 19.2434 18.532 17.8132 18.1732L13.0855 16.9749C9.27854 16.0072 8.61092 13.8881 8.61092 11.9252C8.61092 7.847 12.3385 6.373 15.8916 6.373C19.1622 6.373 23.051 8.17769 23.051 10.625C23.051 11.6733 22.1714 12.2434 21.1494 12.2434C19.2078 12.2434 19.5316 9.51292 15.6209 9.51292C13.6793 9.51292 12.656 10.4218 12.656 11.6989C12.656 12.9729 14.1575 13.4055 15.4884 13.713L18.9871 14.5019C22.8203 15.3677 23.8455 17.615 23.8455 19.7553C23.8455 23.0691 21.2794 25.627 16.2223 25.627Z"
        fill="#00AFF0"
      />
    </Svg>
  )
}

export default Icon
