import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="44" height="32" viewBox="0 0 44 32" fill="#8D8D8D" {...props}>
      <path
        d="M36.6836 2.67933C33.9087 1.41022 30.9797 0.509605 27.9711 0.000412319C27.5627 0.732114 27.1887 1.49763 26.8585 2.26891C25.2582 2.02748 23.6421 1.90597 22.0237 1.90541C20.4079 1.90541 18.7842 2.0282 17.1883 2.26578C16.8624 1.49639 16.4838 0.733516 16.073 0C13.0684 0.512265 10.1319 1.41805 7.35854 2.68461C1.84444 10.8419 0.350111 18.7954 1.09723 26.6359C4.32609 29.0208 7.93983 30.8346 11.7815 31.9985C12.643 30.8355 13.4148 29.5975 14.07 28.3091C12.8199 27.8432 11.6135 27.2675 10.465 26.5887C10.7673 26.3695 11.0628 26.1437 11.3484 25.9245C14.6878 27.4929 18.3368 28.311 22.0286 28.311C25.7205 28.311 29.3695 27.4929 32.7091 25.9242C32.998 26.1601 33.2936 26.3859 33.5924 26.5885C32.4416 27.2686 31.2331 27.8458 29.9808 28.3135C30.6383 29.6046 31.4039 30.8379 32.2694 32C36.1145 30.8397 39.7312 29.026 42.9608 26.6383L42.9599 26.6392C43.8367 17.5467 41.4622 9.66632 36.6836 2.67933ZM15.015 21.8141C12.9329 21.8141 11.2126 19.9245 11.2126 17.5998C11.2126 15.2753 12.873 13.3691 15.0083 13.3691C17.1437 13.3691 18.8505 15.2753 18.814 17.5998C18.7775 19.9245 17.137 21.8141 15.015 21.8141ZM29.0422 21.8141C26.9567 21.8141 25.2432 19.9245 25.2432 17.5998C25.2432 15.2753 26.9036 13.3691 29.0422 13.3691C31.1809 13.3691 32.8745 15.2753 32.838 17.5998C32.8014 19.9245 31.1643 21.8141 29.0422 21.8141Z"
        fill="#5865F2"
      />
    </Svg>
  )
}

export default Icon
