import { createGlobalStyle } from 'styled-components'
import 'assets/fonts/Manrope/style.css'

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  * {
    font-family: Manrope-Regular;
  }
  body {
    background: ${({ theme }) => theme.colors.background};
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;

    img {
      height: auto;
      max-width: 100%;
    }
  }
  /* canvas {
    border: 2px solid red;
    background-color: ${({ theme }) => theme.colors.background};
    /* display: block;
    background: url(resources/images/daikanyama.jpg) no-repeat center center;
    background-size: cover; }*/

    ::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
    }
    
    ::-webkit-scrollbar
    {
      width: 4px;
    }
    
    ::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: ${({ theme }) => theme.colors.primary};
    }
    
`

export default GlobalStyle
