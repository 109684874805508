import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="46" height="32" viewBox="0 0 46 32" fill="#8D8D8D" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.0636 6.9039C45.0636 6.9039 44.6194 3.76803 43.2526 2.39113C41.5205 0.580117 39.5811 0.568388 38.6944 0.463468C32.3329 -2.37269e-07 22.7784 0 22.7784 0H22.7616C22.7616 0 13.2113 -2.37269e-07 6.8464 0.463468C5.95643 0.571729 4.01953 0.579335 2.28828 2.39113C0.920617 3.76803 0.485725 6.9039 0.485725 6.9039C0.485725 6.9039 0.0332031 10.5838 0.0332031 14.2713V17.7195C0.0332031 21.3994 0.485725 25.0869 0.485725 25.0869C0.485725 25.0869 0.929858 28.2227 2.28828 29.6021C4.01953 31.4131 6.29479 31.3543 7.31072 31.5475C10.9529 31.8951 22.7817 32 22.7817 32C22.7817 32 32.3446 31.9832 38.7062 31.5281C39.5961 31.4232 41.5322 31.4123 43.2643 29.6004C44.6319 28.2235 45.0752 25.0843 45.0752 25.0843C45.0752 25.0843 45.5279 21.4045 45.5279 17.7169V14.2688C45.5186 10.5888 45.066 6.90141 45.066 6.90141L45.0636 6.9039ZM18.0658 21.9031V9.1171L30.3557 15.5315L18.0658 21.9031Z"
        fill="#FE0000"
      />
    </Svg>
  )
}

export default Icon
