import { Flex, Box } from 'components/Box'
import styled from 'styled-components'

export const LayoutForTechStack = styled(Flex)`
  margin-bottom: 160px;
  &#partners {
    padding-top: 160px;
  }
  .first-container {
    width: 25%;
  }
  .last {
    width: 75%;
  }
  @media screen and (max-width: 1145px) {
    .first-container {
      min-width: 300px;
    }
    .last {
      width: calc(100% - 350px);
      margin-top: 80px;
      justify-content: center;
    }
  }
  ${({ theme }) => theme.mediaQueries.maxMd} {
    display: block;
    margin-bottom: 80px;

    .first-container {
      width: 100%;
    }
    .last {
      width: 100%;
      margin-top: 80px;
      justify-content: center;
    }
    &#partners {
      padding-top: 80px;
    }
  }
`

export const AppContainer = styled(Box)`
  max-width: 1680px;
  margin: auto;
  .m-0{
    margin: 0;
  }
  ${({ theme }) => theme.mediaQueries.maxXxxl} {
    padding: 0 5%;
  }
  ${({ theme }) => theme.mediaQueries.maxLg} {
    padding: 0 16px;
  }
`
