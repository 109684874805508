import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = () => {
    return (
        <Svg width="17" height="20" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 22V26H2V22H0V26C0 26.5304 0.210714 27.0391 0.585786 27.4142C0.960859 27.7893 1.46957 28 2 28H22C22.5304 28 23.0391 27.7893 23.4142 27.4142C23.7893 27.0391 24 26.5304 24 26V22H22ZM22 12L20.59 10.59L13 18.17V0H11V18.17L3.41 10.59L2 12L12 22L22 12Z" fill="#FF5B00" />
        </Svg>
    )
}

export default Icon
