import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="33" height="32" viewBox="0 0 33 32" fill="#8D8D8D" {...props}>
      <path
        fill="#0a66c2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.39776 0H25.0278C29.0369 0 32.2869 3.25 32.2869 7.25908V24.7409C32.2869 28.75 29.0369 32 25.0278 32H7.39776C3.38867 32 0.138672 28.75 0.138672 24.7409V7.25908C0.138672 3.25 3.38867 0 7.39776 0ZM17.0702 12.4037H13.0083V25.8865H17.2403V19.2396C17.2403 17.5562 17.5788 15.9293 19.7839 15.9293C21.9558 15.9293 21.9866 17.8532 21.9866 19.3482L21.9872 25.8865H26.223V18.5451C26.223 15.0729 25.43 12.4037 21.1408 12.4037C19.0782 12.4037 17.6949 13.4711 17.1292 14.4858H17.0702V12.4037ZM8.40523 10.5194C9.62084 10.5194 10.6079 9.53232 10.6079 8.31672C10.6079 7.10048 9.62084 6.11406 8.40523 6.11406C7.189 6.11406 6.20195 7.10048 6.20195 8.31672C6.20195 9.53232 7.189 10.5194 8.40523 10.5194ZM10.6079 12.4037H6.20195V25.8865H10.6079V12.4037Z"
      />
    </Svg>
  )
}

export default Icon
