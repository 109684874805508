import React from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
const Home = React.lazy(() => import('views/Home'))

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
])
export default routes
