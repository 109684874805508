import React from 'react'
import styled, { keyframes } from 'styled-components'
import { AppLogo } from 'components/Svg'
import { RowBetween } from 'components/Box/Flex'
import HeaderLinkArray from 'constants/headerLinks'

interface MobileMenuProps {
  onDismiss: () => void
  visible?: boolean
}

const MobileNav: React.FC<MobileMenuProps> = ({ onDismiss, visible }) => {
  if (visible) {
    return (
      <StyledMobileMenuWrapper>
        <StyledBackdrop onClick={onDismiss} />
        <StyledMobileMenu>
          <RowBetween>
            <AppLogo width={150} height={100} />
            <img src={'/images/close-btn.svg'} alt="" onClick={onDismiss} />
          </RowBetween>
          {HeaderLinkArray.map(item => (
            <StyledLink key={item?.route} href={item.route} onClick={onDismiss}>
              {item?.name}
            </StyledLink>
          ))}
        </StyledMobileMenu>
      </StyledMobileMenuWrapper>
    )
  }
  return null
}

const StyledBackdrop = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 20%;
`

const StyledMobileMenuWrapper = styled.div`
  background: #00000040;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  backdrop-filter: blur(2px);
`

const slideIn = keyframes`
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%);
  }
`

const StyledMobileMenu = styled.div`
  animation: ${slideIn} 0.3s forwards ease-out;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 68%;
  overflow-y: scroll;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0px 20px 40px 0px;
  img {
    width: 24px;
    height: 24px;
  }
`

const StyledLink = styled.a`
  box-sizing: border-box;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-top: 20px;
  text-transform: capitalize;
  color: #ffffff;
  &.isDisabled {
    pointer-events: none !important;
  }
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }
  &.yellow-color {
  }
`

export default MobileNav
