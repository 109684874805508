interface HeaderLink {
  name: string
  route: string
  disabled: boolean
}
const HeaderLinkArray: HeaderLink[] = [
  {
    name: 'Services',
    route: '#services',
    disabled: false,
  },
  {
    name: 'Partners',
    route: '#partners',
    disabled: false,
  },
  {
    name: 'Tech Stack',
    route: '#stacks',
    disabled: false,
  },
  {
    name: 'Projects',
    route: '#projects',
    disabled: false,
  },
]

export default HeaderLinkArray
