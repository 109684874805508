import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg width="45" height="32" viewBox="0 0 45 32" fill="#8D8D8D" {...props}>
      <path
        d="M2.53822 0H41.715C42.4132 0 43.0478 0.285946 43.5073 0.745116C43.9672 1.20501 44.2528 1.84064 44.2528 2.53822V29.4614C44.2528 29.9876 44.0904 30.4777 43.8131 30.8843C43.7623 31.0158 43.6831 31.1382 43.5747 31.2423C43.5023 31.3103 43.4227 31.3662 43.3384 31.4083C42.8973 31.776 42.3304 32 41.715 32H2.53822C1.84064 32 1.20537 31.7144 0.745116 31.2545C0.285946 30.795 0 30.1604 0 29.4614V2.53822C0 1.8392 0.285226 1.20429 0.744756 0.745116C1.20429 0.285226 1.8392 0 2.53822 0ZM1.94688 28.3936L15.6766 14.6293L1.94688 3.43495V28.3936ZM17.1888 15.8621L3.03376 30.0528H41.1002L27.5729 15.8642L23.1133 19.664C22.7637 19.9629 22.24 19.9798 21.8698 19.6795L17.1888 15.8621ZM29.0534 14.603L42.3059 28.5035V3.31251L29.0534 14.603ZM3.1933 1.94688L22.468 17.6627L40.9151 1.94688H3.1933Z"
        fill="#565656"
      />{' '}
    </Svg>
  )
}

export default Icon
