import Header from 'components/Header'
import Providers from 'Providers'
import { RouterProvider } from 'react-router-dom'
import routes from 'routes'
import GlobalStyle from 'style/Global'

const App = () => {
  return (
    <Providers>
      <GlobalStyle />
      <Header />
      <RouterProvider router={routes} />
    </Providers>
  )
}

export default App
